import { IFRAME_INIT } from 'features/VidyoConnector/AdHocRoom/actions/types';
import { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { END_CALL } from 'store/actions/types/call';
import { CAMERA_TURN_OFF, MICROPHONE_TURN_OFF } from 'store/actions/types/devices';
import { SET_USER, UPDATE_USER } from 'store/actions/types/user';
import storage from 'utils/storage';

// Function to send a message to the parent window
export const sendMessageToParent = (eventName, data) => {
  console.log('Sending message to parent:', eventName, data); // Debugging message

  const event = {
    type: eventName,
    data: data,
  };

  window.parent.postMessage(event, '*');
};

const MessageListener = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('MessageListener mounted'); // Debugging message

    const handleReceiveMessage = (event) => {
      const action = event.data.type;

      switch (action) {
        case 'mount response': {
          const { lastName, firstName, vidyo } = event.data.data.message;
          const { roomUrl, authToken = '' } = vidyo;
    
          const params = roomUrl?.split("/join/");

          storage.addItem('user', { authToken, portal: 'sentryhealth.platform.vidyo.io' });
          storage.addItem('roomKey', params?.[1]);

          setTimeout(() => {
            dispatch({ type: CAMERA_TURN_OFF });
            dispatch({ type: MICROPHONE_TURN_OFF });
          }, 1500);

          dispatch({
            type: IFRAME_INIT,
            payload: {
              roomUrl,
              portal: params?.[0],
              roomKey: params?.[1],
              roomPin: '',
            }
          });

          dispatch({
            type: SET_USER
          });

          dispatch({
            type: UPDATE_USER,
            data: {
              displayName: firstName, name: `${firstName} ${lastName}`,
              vidyo,
            },
          });
          break;
        }
        case 'disconnect': {
          sendMessageToParent('endCallIntent', {});
          dispatch({ 
            type: END_CALL, 
            data: {},
          });
          break;
        }
        default: {
          console.warn('Iframe api method not supported.');
        }
      }
    };

    window.addEventListener('message', handleReceiveMessage);
    return () => {
      window.removeEventListener('message', handleReceiveMessage);
    };
  }, [dispatch]);

  useEffect(() => {
    sendMessageToParent('mount', { data: 'mounted' });
  }, []);

  return null;
};

// Connect the component to the Redux store
export default MessageListener;
