export const ENABLE_GOOGLE_ANALYTICS =
  "VIDYO_CONNECTOR::ANALYTICS::ENABLE_GOOGLE_ANALYTICS";
export const SET_GOOGLE_ANALYTICS_WEB_PROPERTY_ID =
  "VIDYO_CONNECTOR::ANALYTICS::SET_GOOGLE_ANALYTICS_WEB_PROPERTY_ID";
export const GET_ANALYTICS_EVENT_TABLE =
  "VIDYO_CONNECTOR::ANALYTICS::GET_ANALYTICS_EVENT_TABLE";
export const GET_ANALYTICS_EVENT_TABLE_SUCCEEDED =
  "VIDYO_CONNECTOR::ANALYTICS::GET_ANALYTICS_EVENT_TABLE_SUCCEEDED";
export const GET_ANALYTICS_EVENT_TABLE_FAILED =
  "VIDYO_CONNECTOR::ANALYTICS::GET_ANALYTICS_EVENT_TABLE_FAILED";
export const CONTROL_ANALYTICS_EVENT_ACTION =
  "VIDYO_CONNECTOR::ANALYTICS::CONTROL_ANALYTICS_EVENT_ACTION";
export const CONTROL_ANALYTICS_EVENT_ACTION_SUCCEEDED =
  "VIDYO_CONNECTOR::ANALYTICS::CONTROL_ANALYTICS_EVENT_ACTION_SUCCEEDED";
export const CONTROL_ANALYTICS_EVENT_ACTION_FAILED =
  "VIDYO_CONNECTOR::ANALYTICS::CONTROL_ANALYTICS_EVENT_ACTION_FAILED";
export const START_ANALYTICS = "VIDYO_CONNECTOR::ANALYTICS::START_ANALYTICS";
export const START_ANALYTICS_SUCCEEDED =
  "VIDYO_CONNECTOR::ANALYTICS::START_ANALYTICS_SUCCEEDED";
export const START_ANALYTICS_FAILED =
  "VIDYO_CONNECTOR::ANALYTICS::START_ANALYTICS_FAILED";
export const STOP_ANALYTICS = "VIDYO_CONNECTOR::ANALYTICS::STOP_ANALYTICS";
export const STOP_ANALYTICS_SUCCEEDED =
  "VIDYO_CONNECTOR::ANALYTICS::STOP_ANALYTICS_SUCCEEDED";
export const STOP_ANALYTICS_FAILED =
  "VIDYO_CONNECTOR::ANALYTICS::STOP_ANALYTICS_FAILED";
