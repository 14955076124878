export const SET_EXT_DATA = "CONFIG::SET_EXT_DATA";
export const SET_EXT_DATA_SUCCEEDED = "CONFIG::SET_EXT_DATA_SUCCEEDED";
export const SET_EXT_DATA_FAILED = "CONFIG::SET_EXT_DATA_FAILED";
export const SET_URL_PARAMS = "CONFIG::SET_URL_PARAMS";
export const SET_PORTAL_FEATURES = "CONFIG::SET_PORTAL_FEATURES";
export const SET_COMPOSITOR_FIXED_PARTICIPANTS =
  "CONFIG::SET_COMPOSITOR_FIXED_PARTICIPANTS";
export const SET_COMPOSITOR_FIXED_PARTICIPANTS_FAILED =
  "CONFIG::SET_COMPOSITOR_FIXED_PARTICIPANTS_FAILED";
export const SEND_SMS = "CONFIG::SEND_SMS";
export const SEND_SMS_SUCCEEDED = "CONFIG::SEND_SMS_SUCCEEDED";
export const SEND_SMS_FAILED = "CONFIG::SEND_SMS_FAILED";
export const SET_STATISTICS_OVERLAY = "CONFIG::SET_STATISTICS_OVERLAY";
export const SET_STATISTICS_OVERLAY_SUCCEEDED =
  "CONFIG::SET_STATISTICS_OVERLAY_SUCCEEDED";
export const SET_STATISTICS_OVERLAY_FAILED =
  "CONFIG::SET_STATISTICS_OVERLAY_FAILED";

export const GET_CUSTOM_PARAMETERS = "CONFIG::GET_CUSTOM_PARAMETERS";
export const GET_CUSTOM_PARAMETERS_SUCCEEDED =
  "CONFIG::GET_CUSTOM_PARAMETERS_SUCCEEDED";
export const GET_CUSTOM_PARAMETERS_FAILED =
  "CONFIG::GET_CUSTOM_PARAMETERS_FAILED";

export const GET_GCP_SERVICES_LIST = "CONFIG::GET_GCP_SERVICES_LIST";
export const GET_GCP_SERVICES_LIST_SUCCEEDED =
  "CONFIG::GET_GCP_SERVICES_LIST_SUCCEEDED";
export const GET_GCP_SERVICES_LIST_FAILED =
  "CONFIG::GET_GCP_SERVICES_LIST_FAILED";
export const RESET_GCP_SERVICES_LIST = "CONFIG::RESET_GCP_SERVICES_LIST";

export const GET_ENDPOINT_BEHAVIOUR = "CONFIG::SET_ENDPOINT_BEHAVIOUR";
export const GET_ENDPOINT_BEHAVIOUR_SUCCEEDED =
  "CONFIG::SET_ENDPOINT_BEHAVIOUR_SUCCEEDED";
export const GET_ENDPOINT_BEHAVIOUR_FAILED =
  "CONFIG::SET_ENDPOINT_BEHAVIOUR_FAILED";

export const SET_JWT_TOKEN = "CONFIG::SET_JWT_TOKEN";
export const SET_REFRESH_TOKEN = "CONFIG::SET_REFRESH_TOKEN";

export const SET_PARTICIPANT_LIMIT = "CONFIG::SET_PARTICIPANT_LIMIT";
export const SET_PARTICIPANT_LIMIT_FAILED =
  "CONFIG::SET_PARTICIPANT_LIMIT_FAILED";

export const SET_DISABLE_PARTICIPANT_RESTRICTIONS_TABLET =
  "CONFIG::SET_DISABLE_PARTICIPANT_RESTRICTIONS_TABLET";
export const SET_DISABLE_PARTICIPANT_RESTRICTIONS_TABLET_FAILED =
  "CONFIG::SET_DISABLE_PARTICIPANT_RESTRICTIONS_TABLET_FAILED";
export const EPIC_CALL_SESSION_INITIALIZED =
  "CONFIG::EPIC_CALL_SESSION_INITIALIZED";

export const EPIC_CALL_SESSION_STARTED = "CONFIG::EPIC_CALL_SESSION_STARTED";

export const EPIC_CALL_SET_DOCUMENT_TYPES =
  "CONFIG::EPIC_CALL_SET_DOCUMENT_TYPES";

export const EPIC_CALL_RESET_SESSION = "CONFIG::EPIC_CALL_RESET_SESSION";

export const SET_GEOLOCATION_URL =
  "CONFIG::SET_GEOLOCATION_URL";
export const SET_GEOLOCATION_URL_SUCCEEDED =
  "CONFIG::SET_GEOLOCATION_URL_SUCCEEDED";
export const SET_GEOLOCATION_URL_FAILED =
  "CONFIG::SET_GEOLOCATION_URL_FAILED";

export const SET_PRODUCT_INFO =
  "CONFIG::SET_PRODUCT_INFO";
export const SET_PRODUCT_INFO_SUCCEEDED =
  "CONFIG::SET_PRODUCT_INFO_SUCCEEDED";
export const SET_PRODUCT_INFO_FAILED =
  "CONFIG::SET_PRODUCT_INFO_FAILED";

export const SET_CONFIG_IFRAME = "CONFIG::SET_CONFIG_IFRAME";
