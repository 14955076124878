export const INIT = "APP::INIT";
export const INIT_SUCCEEDED = "APP::INIT_SUCCEEDED";
export const INIT_FAILED = "APP::INIT_FAILED";
export const GENERATE_LOGS = "APP::GENERATE_LOGS";
export const GENERATE_LOGS_SUCCEEDED = "APP::GENERATE_LOGS_SUCCEEDED";
export const GENERATE_LOGS_FAILED = "APP::GENERATE_LOGS_FAILED";
export const ENABLE_DEBUG_LOG_LEVEL = "APP::ENABLE_DEBUG_LOG_LEVEL";
export const DIACTIVATE_TAB = "APP::DIACTIVATE_TAB";
export const UNINIT = "APP::UNINIT";
export const PERMISSIONS_CHANGES_SUBSCRIBE =
  "APP::PERMISSIONS_CHANGES_SUBSCRIBE";
export const PERMISSIONS_CHANGES_SUBSCRIBE_SUCCEEDED =
  "APP::PERMISSIONS_CHANGES_SUBSCRIBE_SUCCEEDED";
export const PERMISSIONS_CHANGES_SUBSCRIBE_FAILED =
  "APP::PERMISSIONS_CHANGES_SUBSCRIBE_FAILED";
