import {
  INIT_IFRAME
} from "../actions/types/iframe";

const initialState = {
  history: [],
  specialMessage: null,
};

const iframe = (state = initialState, action) => {
  switch (action.type) {
    case INIT_IFRAME:
      return {
        ...state,
        history: [...state.history, action.payload],
      };

    default:
      return state;
  }
};

export default iframe;
